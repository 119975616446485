import React from 'react';

import { useOutletContext } from 'react-router-dom';
import Header from 'components/header/Header';
import MediaCards from 'components/cards/MediaCards/MediaCards';
import { useContentfulQueryData } from 'hooks/useContentfulQueryData';

const Exercise = (props: { dataKey: string }) => {
  const { contentfulData, loading } = useContentfulQueryData(props.dataKey);
  const { routeTheme }: { routeTheme: string } = useOutletContext();

  return (
    <React.Fragment>
      <Header />
      <div
        className={`${
          loading && `loader-10 loader-link-${routeTheme} mx-auto mt-3`
        }`}
      ></div>
      {<MediaCards data={contentfulData} />}
    </React.Fragment>
  );
};

export default Exercise;
