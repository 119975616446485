import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useOutletContext } from 'react-router-dom';
import Header from 'components/header/Header';
import SideBar from 'components/training/SideBar/SideBar';
import TrainingContent from 'components/training/TrainingContent/TrainingContent';
import { useBodyTraining } from 'hooks/useBodyTraining';

import body from 'images/bodybg.png';
import { type IOutletContext } from 'globals/utils/types';

const BodyTrainingLayout = () => {
  const { t } = useTranslation();
  const outletContext: IOutletContext = useOutletContext();
  const { blocksData, setSelectedItem, selectedBlock, tasksBySelectedBlock } =
    useBodyTraining(outletContext.data?.body);

  const selectedBlockData =
    blocksData.find(block => block.id === selectedBlock) ?? null;
  return (
    <div className={'row'}>
      <SideBar
        type={t('@T_Movements')}
        data={blocksData}
        selectedId={selectedBlock}
        setSelectedId={setSelectedItem}
      />
      <TrainingContent
        bgImage={body}
        bgImageBgcolor='bg-pink'
        selectedId={selectedBlock}
        data={tasksBySelectedBlock}
        selectedItemData={selectedBlockData}
      >
        <Trans i18nKey='@T_Body_Training_Description'>
          <strong>Let&apos;s start!</strong> Choose a move. We recommend you
          start with the introduction.
        </Trans>
      </TrainingContent>
    </div>
  );
};

const BodyTraining = () => {
  return (
    <React.Fragment>
      <Header />
      <BodyTrainingLayout />
    </React.Fragment>
  );
};

export default BodyTraining;
