import styles from './TrainingCard.module.scss';
import { CardType } from 'globals/utils/types';

interface TrainingCardProps {
  className?: string;
  cardType?: CardType;
  bgImage: string;
  bgImageBgcolor: string;
  cardData: {
    title: string;
    button_text: string;
    description: string;
  };
  onClick: () => void;
}

const TrainingCard = ({
  className,
  cardType = CardType.PRIMARY,
  bgImage,
  bgImageBgcolor,
  cardData,
  onClick,
}: TrainingCardProps): JSX.Element => {
  return (
    <div className={className} onClick={onClick}>
      <div className={`${styles.card} card bg-gray`}>
        <div className={`${styles.bg_image_background} ${bgImageBgcolor}`} />
        <div className={`${styles.bg_image} ${bgImage} p-3 pr-0`}>
          <h5 className={`link-${cardType} font-17 mb-1`}>{cardData.title}</h5>
          <p className='font-14 mb-3 col-8'>{cardData.description}</p>
          <button className={`btn-${cardType} font-17 py-1 px-5 mt-5`}>
            {cardData.button_text}
          </button>
        </div>
      </div>
    </div>
  );
};

export default TrainingCard;
