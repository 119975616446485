import styles from './ExerciseCard.module.scss';
import { CardType } from 'globals/utils/types';

interface ExerciseCardProps {
  className?: string;
  cardType?: CardType;
  icon: string;
  title: string;
  onClick: () => void;
}

const ExerciseCard = ({
  className,
  cardType = CardType.PRIMARY,
  icon,
  title,
  onClick,
}: ExerciseCardProps): JSX.Element => {
  return (
    <div className={`${className} ${styles.main}`} onClick={onClick}>
      <div className={`${styles.card} h-100 card bg-gray`}>
        <img src={icon} className={`${styles.card_logo}`} alt={title} />
        <h5 className={`link-${cardType} font-17`}>{title}</h5>
      </div>
    </div>
  );
};

export default ExerciseCard;
