import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './Time.module.scss';
import { secondsToTime } from 'globals/utils/utils';
import clock from 'images/clock.svg';

interface TimeProps {
  duration: number;
}

const Time = ({ duration }: TimeProps) => {
  const { t } = useTranslation();
  const imgDurationAlt = t('@T_Duration');
  return (
    <div className={`bg-lgray font-14 d-flex ${styles.time}`}>
      <img src={clock} height={18} width={18} alt={imgDurationAlt} />
      <span>&nbsp;{secondsToTime(duration)}</span>
    </div>
  );
};

export default Time;
