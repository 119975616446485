import { useMemo, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { type ITeamAccessBodyData } from '../globals/utils/types';

export const useBodyTraining = (data?: ITeamAccessBodyData) => {
  const location = useLocation();

  const [selectedBlock, setSelectedBlock] = useState('');

  useEffect(() => {
    if (location.state) {
      setSelectedBlock(location?.state?.selectedId);
    } else {
      setSelectedBlock('');
    }
  }, [location.state, location.state?.selectedId]);

  const blocks = useMemo(() => data?.blocks ?? [], [data]);
  const tasks = useMemo(() => data?.tasks ?? [], [data]);

  const blocksData = blocks.map(item => ({
    id: item.content.id,
    title: item?.content?.title,
    description: item?.content?.description,
    imgSrc: item?.content?.imgSrc,
  }));

  const tasksBySelectedBlock = tasks.filter(
    task => task.parentBlockId === selectedBlock,
  );

  const setSelectedItem = (id: string) => {
    setSelectedBlock(id);
  };

  return useMemo(
    () => ({
      blocksData,
      selectedBlock,
      setSelectedItem,
      tasks,
      tasksBySelectedBlock,
    }),
    [blocksData, selectedBlock, tasks, tasksBySelectedBlock],
  );
};
