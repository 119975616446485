import { gql, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { ContentfulMediaQuery } from 'apis/ContentfulQueries';
import {
  Language,
  type ResolvedLanguage,
  type MediaCardResult,
  type ContentfulMediaQueryVariables,
  type ContentfulMediaQueryResult,
} from 'globals/utils/types';

export const useContentfulQueryData = (key: string) => {
  const limit = 20;
  const { lng }: { lng: ResolvedLanguage } = useOutletContext();
  const [contentfulResponseData, setContentfulResponseData] = useState<
    MediaCardResult[]
  >([]);
  const [skip, setSkip] = useState(0);
  const [contentfulData, setContentfulData] = useState<
    Record<string, MediaCardResult[]>
  >({});

  const groupByTags = (datam: MediaCardResult[]) => {
    return datam.reduce((group: Record<string, MediaCardResult[]>, item) => {
      const { contentfulMetadata } = item;
      const tag = contentfulMetadata.tags[0];
      const tagId = tag.id;
      group[tagId] = group[tagId] ?? [];
      group[tagId].push({ ...item });
      return { ...group };
    }, {});
  };

  const { data: contentfulResponse, loading } = useQuery<
    ContentfulMediaQueryResult,
    ContentfulMediaQueryVariables
  >(
    gql`
      ${ContentfulMediaQuery}
    `,
    {
      variables: { locale: Language[lng], key, skip },
    },
  );

  useEffect(() => {
    setSkip(0);
    setContentfulData({});
    setContentfulResponseData([]);
  }, [lng]);

  const responseData =
    contentfulResponse?.mediaCategoryCollection?.items[0].itemsCollection;

  useEffect(() => {
    if (responseData?.items) {
      if (skip === 0) {
        setContentfulResponseData(responseData?.items);
      } else {
        setContentfulResponseData([
          ...contentfulResponseData,
          // eslint-disable-next-line no-unsafe-optional-chaining
          ...responseData?.items,
        ]);
      }
    }
    if (responseData?.total && skip + limit < responseData?.total) {
      setSkip(skip + limit);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentfulResponse]);

  useEffect(() => {
    if (responseData?.total === contentfulResponseData.length) {
      setContentfulData(groupByTags(contentfulResponseData));
    }
  }, [
    contentfulResponseData.length,
    responseData?.total,
    lng,
    contentfulResponseData,
  ]);

  return {
    contentfulData,
    contentfulResponseData,
    loading,
  };
};
