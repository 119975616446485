import React, {
  createContext,
  type ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

interface ViewportContextValue {
  width: number;
  height: number;
}

const ViewportContext = createContext<ViewportContextValue>({
  width: 0,
  height: 0,
});

interface Props {
  children: ReactNode;
}

export const ViewportProvider = ({ children }: Props) => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const handleWindowResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const contextValue = useMemo(
    () => ({
      width,
      height,
    }),
    [height, width],
  );

  return (
    <ViewportContext.Provider value={contextValue}>
      {children}
    </ViewportContext.Provider>
  );
};

export const useViewport = () => {
  const { width, height } = useContext(ViewportContext);
  return { width, height };
};
