import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { Routes as ConfigRoutes } from './config/routes.config';
import Layout from './components/layout/Layout';
import Home from './pages/home/Home';
import Login from './pages/login/Login';
import BodyTraining from './pages/body/BodyTraining';
import MindTraining from './pages/mind/MindTraining';
import Player from './pages/player/Player';
import Exercise from './pages/exercise/Exercise';
import { ContentfulMediaKeys } from './globals/utils/types';

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const routes = sentryCreateBrowserRouter([
  {
    path: ConfigRoutes.HOME,
    element: <Layout />,
    children: [
      {
        path: ConfigRoutes.LOG_IN,
        element: <Login />,
      },
      {
        path: ConfigRoutes.HOME,
        element: <Home />,
      },
      {
        path: ConfigRoutes.BODY_TRAINING,
        element: <BodyTraining />,
      },
      {
        path: ConfigRoutes.BODY_EXERCISES,
        element: <Exercise dataKey={ContentfulMediaKeys.BODY} />,
      },
      {
        path: ConfigRoutes.MIND_TRAINING,
        element: <MindTraining />,
      },
      {
        path: ConfigRoutes.MIND_EXERCISES,
        element: <Exercise dataKey={ContentfulMediaKeys.MIND} />,
      },
      {
        path: ConfigRoutes.MIND_CALMING_SOUNDS,
        element: <Exercise dataKey={ContentfulMediaKeys.CALMINGSOUNDS} />,
      },
      {
        path: ConfigRoutes.PLAYER,
        element: <Player />,
      },
    ],
  },
]);

export default function App() {
  return <RouterProvider router={routes} />;
}
