import { useEffect, useMemo, useState } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';

import { useBodyTraining } from './useBodyTraining';
import { useMindTraining } from './useMindTraining';
import { useContentfulQueryData } from './useContentfulQueryData';
import {
  type PlayerDataProps,
  type MediaCardResult,
  type TrainingTask,
  type MindTask,
  type IOutletContext,
  MediaCategory,
  MediaType,
  ContentfulMediaKeys,
} from 'globals/utils/types';

enum Type {
  BODY = 'body',
  MIND = 'mind',
}

export const useFetchMediaPlayerData = (initialData: PlayerDataProps) => {
  const { category, type, id } = useParams();
  const outletContext: IOutletContext = useOutletContext();
  const { tasks: bodyTaskData } = useBodyTraining(outletContext.data?.body);
  const { tasks: mindTaskData } = useMindTraining(outletContext.data?.mind);

  const dataSet = useMemo(
    () => ({
      [`${Type.BODY}-${MediaCategory.TRAINING}`]: bodyTaskData,
      [`${Type.MIND}-${MediaCategory.TRAINING}`]: mindTaskData,
    }),
    [bodyTaskData, mindTaskData],
  );

  const { contentfulResponseData } = useContentfulQueryData(
    category === MediaCategory.CALMINGSOUNDS
      ? ContentfulMediaKeys.CALMINGSOUNDS
      : type === Type.BODY
      ? ContentfulMediaKeys.BODY
      : ContentfulMediaKeys.MIND,
  );

  const [playerData, setPlayerData] = useState<PlayerDataProps | null>(
    initialData,
  );

  useEffect(() => {
    const fetchMediaData = (
      data: Array<TrainingTask | MindTask | MediaCardResult> | null,
    ) => {
      const media = data?.find(
        (item: TrainingTask | MindTask | MediaCardResult) =>
          ('id' in item ? item?.id : item?.sys.id) === id,
      );

      if (media) {
        if ('id' in media) {
          setPlayerData({
            url: media.content.videoSrc ?? media.content.audioSrc,
            title: media.content.title,
            image: media.content.imgSrc,
            type: media.content.audioSrc ? MediaType.AUDIO : MediaType.VIDEO,
            selectedId:
              (media as TrainingTask).parentBlockId ??
              (media as MindTask).packageId,
            contentId: media.content.sys.id,
          });
        } else {
          setPlayerData({
            url: media?.media.url,
            title: media?.title,
            image: media?.image.url,
            type: media?.contentType,
            contentId: media.sys.id,
          });
        }
      }
    };
    if (type && category) {
      fetchMediaData(
        category === MediaCategory.TRAINING
          ? dataSet[`${type}-${category}`]
          : contentfulResponseData,
      );
    }
  }, [category, contentfulResponseData, dataSet, id, type]);

  return {
    playerData,
  };
};
