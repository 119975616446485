export const ContentfulMediaQuery =
  /* GraphQL */
  `
    query ($locale: String!, $key: String!, $skip: Int!) {
      mediaCategoryCollection(where: { key: $key }, locale: $locale) {
        items {
          itemsCollection(limit: 20, skip: $skip) {
            total
            items {
              sys {
                id
              }
              title
              infoContent
              durationSeconds
              contentType
              image {
                url
              }
              expiryDateForNew
              media {
                url
              }
              contentfulMetadata {
                tags {
                  name
                  id
                }
              }
            }
          }
        }
      }
    }
  `;

export const ContentfulMediaTranslationsQuery =
  /* GraphQL */
  `
    query ($locale: String!) {
      translationsCollection(
        where: {
          key_contains: "@T_TAG_MEDIA"
          key_not: "@T_TAG_MEDIA_BONUS_WORKOUT"
        }
        locale: $locale
      ) {
        total
        items {
          key
          value
          description
        }
      }
    }
  `;
