import { useState } from 'react';
import { getTeamAccessData } from '../services/networkService';
import { type ITeamAccessData } from '../globals/utils/types';

export const useTeamAccessData = (
  tenantAccessId: string | null,
  accessCode: string | null,
  locale: string,
) => {
  const [data, setData] = useState<ITeamAccessData>();
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const fetch = () => {
    setLoading(true);
    return getTeamAccessData(tenantAccessId, accessCode, locale)
      .then(response => {
        setData(response);
      })
      .catch((e: Error) => {
        setError(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    loading,
    data,
    error,
    fetch,
  };
};
