import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useOutletContext } from 'react-router-dom';
import styles from './Header.module.scss';
import { Routes } from 'config/routes.config';

import back from 'images/back.svg';

const Header = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { routeTheme }: { routeTheme: string } = useOutletContext();
  const imgBackAlt = t('@T_Back');

  return (
    <header
      className={`${styles.header} ${styles[routeTheme]} d-flex py-3 col-12 white`}
    >
      <img
        src={back}
        className={`ml-3 mr-1 ${styles.back_button} c-pointer`}
        alt={imgBackAlt}
        onClick={() => {
          navigate(Routes.HOME);
        }}
      />
      <h5 className='font-17'>{t('@T_Back')}</h5>
    </header>
  );
};

export default Header;
