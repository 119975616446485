import React from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

import styles from './SideBar.module.scss';
import { type SelectedTrainingContentData } from 'globals/utils/types';

interface SideBarProps {
  data: SelectedTrainingContentData[];
  type: string;
  selectedId: string;
  setSelectedId: (id: string) => void;
}

const SideBar = ({ data, type, selectedId, setSelectedId }: SideBarProps) => {
  const { t } = useTranslation();

  const { routeTheme }: { routeTheme: string } = useOutletContext();

  const ShowCards = ({
    datam,
    startsFrom = 0,
  }: {
    datam: SelectedTrainingContentData[];
    startsFrom?: number;
  }) => {
    return (
      <React.Fragment>
        {datam.map((unit, index) => (
          <div
            key={unit.id}
            className={`d-flex py-1 c-pointer ${
              selectedId === unit.id ? 'pl-1' : 'pr-3'
            } a-i-center`}
            onClick={() => {
              setSelectedId(selectedId === unit.id ? '' : unit.id);
            }}
          >
            {selectedId === unit.id ? (
              <div className={`${styles.active_line} bg-link-${routeTheme}`} />
            ) : (
              <p className={`link-${routeTheme} pr-1`}>{`${
                index + startsFrom <= 9 ? 0 : ''
              }${index + startsFrom}`}</p>
            )}
            <div
              className={`bg-gray w-100 ${styles.card} ${
                selectedId === unit.id && `${styles.active} link-${routeTheme}`
              }`}
            >
              <p className='font-17 p-2'>{unit.title}</p>
            </div>
          </div>
        ))}
      </React.Fragment>
    );
  };
  return (
    <div className={`${styles.w_300} p-3 pr-0`}>
      <h5 className='font-20 pb-2'>{t('@T_Basics')}</h5>
      <ShowCards datam={data.slice(0, 1)} />
      <h5 className='font-20 py-2 mt-2'>{type}</h5>
      <ShowCards datam={data.slice(1)} startsFrom={1} />
    </div>
  );
};

export default SideBar;
