export enum RouteThemeProps {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}
export type CardType = RouteThemeProps;
// eslint-disable-next-line @typescript-eslint/no-redeclare, no-redeclare
export const CardType = { ...RouteThemeProps };

export enum MediaType {
  VIDEO = 'video',
  AUDIO = 'audio',
}

export enum MediaCategory {
  EXERCISES = 'exercises',
  TRAINING = 'training',
  CALMINGSOUNDS = 'calming-sounds',
}

export type ResolvedLanguage = 'en' | 'de';

export enum Language {
  'de' = 'de-DE',
  'en' = 'en-US',
}

export interface IOutletContext {
  lng: string;
  routeTheme: RouteThemeProps;
  data?: ITeamAccessData;
}

export enum ContentfulMediaKeys {
  MIND = '@MIND',
  BODY = '@BODY_SINGLE_EXERCISES',
  CALMINGSOUNDS = '@MUSIC',
}

export interface ContentfulContent {
  animationSrc: string | null;
  audioSrc: string | null;
  description: string;
  id: string;
  imgSrc: string | null;
  sys: {
    id: string;
    revision: number;
  };
  title: string;
  videoSrc: string | null;
}

export interface ContentfulTaskContent extends ContentfulContent {
  body: string;
  durationSeconds: number;
}

export interface ContentfulBlockContent extends ContentfulContent {}
export interface ContentfulPackageContent extends ContentfulContent {
  body: string;
}

export interface ITeamAccessBodyData {
  tasks: TrainingTask[];
  blocks: TrainingBlock[];
}

export interface ITeamAccessMindData {
  tasks: MindTask[];
  packages: MindPackage[];
}

export interface ITeamAccessTenantData {
  name: string;
  id: string;
}

export interface ITeamAccessData {
  tenant: ITeamAccessTenantData;
  body: ITeamAccessBodyData;
  mind: ITeamAccessMindData;
}

export interface TrainingTask {
  id: string;
  parentBlockId: string;
  parentModuleId: string;
  taskPredecessor: string | null;
  taskType: string;
  unitId: string;
  unitPredecessor: string | null;
  content: ContentfulTaskContent;
}

export interface MindTask {
  id: string;
  packageId: string;
  task_predecessor: string | null;
  type: string;
  content: ContentfulTaskContent;
}

export interface TrainingBlock {
  id: string;
  blockPredecessor: string | null;
  isModuleEntryPoint: boolean;
  jobType: string;
  moduleId: string;
  content: ContentfulBlockContent;
}

export interface MindPackage {
  id: string;
  jobType: string;
  packageGroup: string;
  packageGroupOrder: number;
  content: ContentfulPackageContent;
}

export interface SelectedTrainingContentData {
  id: string;
  title: string;
  description: string;
  imgSrc: string | null;
}

export interface MediaCardResult {
  sys: {
    id: string;
  };
  title: string;
  infoContent: string | null;
  durationSeconds: number;
  contentType: MediaType;
  image: {
    url: string;
  };
  expiryDateForNew: string;
  media: {
    url: string;
  };
  contentfulMetadata: {
    tags: [
      {
        name: string;
        id: string;
      },
    ];
  };
}

export interface PlayerDataProps {
  url: string | null;
  title: string;
  image: string | null;
  type: MediaType;
  selectedId?: string;
  contentId?: string;
}

export interface ContentfulMediaQueryResult {
  mediaCategoryCollection: {
    items: [
      {
        itemsCollection: {
          total: number;
          items: MediaCardResult[];
        };
      },
    ];
  };
}

export interface ContentfulMediaQueryVariables {
  locale: Language;
  key: string;
  skip: number;
}

export interface ContentfulMediaTranslations {
  key: string;
  value: string;
  description: string;
}

export interface ContentfulMediaTranslationsQueryResult {
  translationsCollection: {
    items: ContentfulMediaTranslations[];
  };
}

export interface ContentfulMediaTranslationsQueryVariables {
  locale: Language;
}
