import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import styles from './Layout.module.scss';
import { Routes, LocalStorageKeys } from 'config';
import {
  type ResolvedLanguage,
  type IOutletContext,
  RouteThemeProps,
  getCodeFromHash,
} from 'globals/utils';
import { ViewportProvider } from 'contexts/viewport/Viewport';
import globe from 'images/globe.svg';
import globe_red from 'images/globe-red.svg';
import { useTeamAccessData } from 'hooks/useTeamAccessData';

type LngsProps = Record<string, { nativeName: string }>;

const lngs: LngsProps = {
  de: { nativeName: 'DE' },
  en: { nativeName: 'EN' },
};

enum ButtonColor {
  WHITE = 'white',
  RED = 'link-primary',
}

const Layout = () => {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const locale = i18n.resolvedLanguage as ResolvedLanguage;
  const imgGlobeAlt = t('@T_Language');
  const [routeTheme, setRouteTheme] = useState<RouteThemeProps>(
    RouteThemeProps.PRIMARY,
  );
  const [buttonColor, setButtonColor] = useState<ButtonColor>(ButtonColor.RED);
  const location = useLocation();
  const hashCode = getCodeFromHash(location.hash);

  const tenantAccessId = hashCode?.length
    ? hashCode
    : localStorage.getItem(LocalStorageKeys.TENANT_ACCESS_ID);
  const accessCode = localStorage.getItem(LocalStorageKeys.ACCESS_CODE);

  const {
    data,
    error,
    fetch: fetchData,
  } = useTeamAccessData(tenantAccessId, accessCode, locale);

  const outletContext: IOutletContext = {
    lng: i18n.resolvedLanguage,
    routeTheme,
    data,
  };

  useEffect(() => {
    if (typeof window && !(tenantAccessId && accessCode)) {
      navigate(`${Routes.LOG_IN}#code=${tenantAccessId ?? ''}`);
    }
    if (tenantAccessId && accessCode) {
      fetchData();
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [location.pathname, locale]);

  useEffect(() => {
    if (error && error.cause === 403) {
      localStorage.removeItem(LocalStorageKeys.ACCESS_CODE);
      navigate(`${Routes.LOG_IN}#code=${tenantAccessId ?? ''}`, {
        state: { error: error.message },
      });
    }
  }, [error, navigate, tenantAccessId]);

  useEffect(() => {
    switch (true) {
      case location.pathname.includes('/body/'):
        setRouteTheme(RouteThemeProps.PRIMARY);
        setButtonColor(ButtonColor.WHITE);
        break;
      case location.pathname.includes('/mind/'):
        setRouteTheme(RouteThemeProps.SECONDARY);
        setButtonColor(ButtonColor.WHITE);
        break;
      default:
        setButtonColor(ButtonColor.RED);
    }
  }, [location]);

  return (
    <React.Fragment>
      <div className={`${styles.button_position} py-3 d-flex`}>
        <img
          src={buttonColor === ButtonColor.WHITE ? globe : globe_red}
          className={`${styles.app_logo}`}
          alt={imgGlobeAlt}
        />
        {Object.keys(lngs).map((lng, index) => (
          <button
            className={`c-pointer ${styles.button} ${
              i18n.resolvedLanguage !== lng && 'o-50'
            } ${
              index < Object.keys(lngs).length - 1 && styles.button_border_right
            } ${buttonColor}`}
            key={lng}
            onClick={() => i18n.changeLanguage(lng)}
          >
            {lngs[lng].nativeName}
          </button>
        ))}
      </div>
      <ViewportProvider>
        <Outlet context={outletContext} />
      </ViewportProvider>
    </React.Fragment>
  );
};

export default Layout;
