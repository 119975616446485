import React, { type ChangeEvent, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './Login.module.scss';
import logo from 'images/logo.svg';
import done from 'images/done.svg';
import close from 'images/close.svg';
import { useTeamAccessData } from 'hooks/useTeamAccessData';
import { type ResolvedLanguage, getCodeFromHash } from 'globals/utils';
import { Routes, LocalStorageKeys } from 'config';

const Login = () => {
  const { t } = useTranslation();
  const imgLogoAlt = t('@T_Title');
  const imgErrorAlt = t('@T_Error');
  const imgDoneAlt = t('@T_Done');
  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const location = useLocation();
  const { i18n } = useTranslation();
  const locale = i18n.resolvedLanguage as ResolvedLanguage;

  const [toastError, setToastError] = useState('');
  const [error, setError] = useState<Error | null>(null);
  const [accessCode, setAccessCode] = useState('');
  const [localTenantAccessId, setLocalTenantAccessId] = useState(
    localStorage.getItem(LocalStorageKeys.TENANT_ACCESS_ID),
  );

  const windowType = typeof window;
  const hashCode = getCodeFromHash(location.hash) ?? '';
  const localAccessCode = localStorage.getItem(LocalStorageKeys.ACCESS_CODE);

  const {
    data,
    error: loginError,
    fetch: fetchData,
    loading,
  } = useTeamAccessData(hashCode, accessCode, locale);

  useEffect(() => {
    loginError && setError(loginError);
  }, [loginError]);

  useEffect(() => {
    location?.state?.error && setToastError(location?.state?.error);
  }, [location?.state?.error]);

  useEffect(() => {
    if (!!typeof window && !!localTenantAccessId && !!localAccessCode) {
      navigate('/');
    } else if (hashCode !== localTenantAccessId) {
      localStorage.setItem(LocalStorageKeys.TENANT_ACCESS_ID, hashCode);
      setLocalTenantAccessId(hashCode);
    }
  }, [windowType, hashCode, localTenantAccessId, localAccessCode, navigate]);

  useEffect(() => {
    inputRef?.current && inputRef?.current.focus();
  }, []);

  const closeToastError = () => {
    setToastError('');
  };

  useEffect(() => {
    if (data) {
      localStorage.setItem(LocalStorageKeys.ACCESS_CODE, accessCode);
      navigate('/');
    }
  }, [data, accessCode, navigate]);

  const onChangeAccessCode = (e: ChangeEvent<HTMLInputElement>) => {
    setAccessCode(e.target.value);
    error && setError(null);
  };

  const isAccessCodeFilled = accessCode.length === 8;
  const showFormError = !toastError && error;

  return (
    <div className={`${styles.main} p-2`}>
      <img src={logo} className={`mt-3 ${styles.app_logo}`} alt={imgLogoAlt} />
      <h2 className='mt-3 py-1 font-32'>{t('@T_Title')}</h2>
      <h2 className='font-w-n font-32'>{t('@T_Sub_Tiltle')}</h2>
      <p className='mt-5 font-24'>{t('@T_Access_Code')}</p>
      <div
        className={`mb-5 col-10 col-sm-6 mt-3 ${styles.input_parent}`}
        style={{ backgroundColor: '#383838' }}
      >
        <div
          className={`bg-lgray p-2 d-flex ${styles.input_parent} ${
            !!showFormError && styles.error_border
          } link-primary`}
          onClick={() => inputRef?.current?.focus()}
        >
          <div className='w-100'>
            <input
              ref={inputRef}
              type='text'
              className={`${
                accessCode && styles.input
              } font-24 m-auto bg-lgray p-0 ${styles.default_input}`}
              onChange={onChangeAccessCode}
              placeholder={t('@T_Login.Access_Code_Placeholder') ?? ''}
              minLength={8}
              maxLength={8}
            />
          </div>
          {isAccessCodeFilled && (
            <img
              src={showFormError ? close : done}
              className={`my-auto ${styles.image_border} ${styles.image}`}
              height={36}
              width={36}
              alt={showFormError ? imgErrorAlt : imgDoneAlt}
            />
          )}
        </div>
        {showFormError && (
          <div className={`${styles.error_message} p-2 white`}>
            {t(`@T_Login.Error_${error.message}`)}
          </div>
        )}
      </div>
      <div className='col-11 col-sm-7 px-3'>
        <button
          disabled={!isAccessCodeFilled}
          className={`${
            !isAccessCodeFilled && 'disabled o-50'
          } p-2 mt-1 mb-3 btn-primary font-24 col-12 d-flex j-c-center`}
          onClick={fetchData}
        >
          {loading ? <div className='loader-4'></div> : t('@T_Login.Login')}
        </button>
      </div>

      {toastError && (
        <div className='white d-flex m-3 p-2 bg-red br-1 a-i-center c-default col-8'>
          <img
            width={28}
            className={'mr-1 c-pointer'}
            onClick={closeToastError}
            src={close}
            alt='error toast'
          ></img>
          {t(`@T_Login.Error_${toastError}`)}
        </div>
      )}

      <p className='font-20 mb-4'>
        <Trans i18nKey='@T_Login.Terms_And_Conditions'>
          By logging in I agree to the
          <a
            className='link-primary'
            target='_blank'
            href={Routes.TERMS_OF_USE}
            rel='noreferrer'
          >
            terms of use
          </a>
          .
        </Trans>
      </p>
    </div>
  );
};

export default Login;
