import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';
import {
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom';

import { type OnProgressProps } from 'react-player/base';
import styles from './Player.module.scss';
import { useFetchMediaPlayerData } from 'hooks/useFetchMediaPlayerData';
import { useAnalytics, AnalyticsEvents } from 'hooks/useAnalytics';
import { secondsToTime } from 'globals/utils/utils';
import {
  MediaCategory,
  MediaType,
  type RouteThemeProps,
} from 'globals/utils/types';

import backImgSrc from 'images/back.svg';
import playImgSrc from 'images/play.svg';
import pauseImgSrc from 'images/pause.svg';
import replayImgSrc from 'images/replay.svg';

enum PlayerStatus {
  Play = 'Play',
  Pause = 'Pause',
  Replay = 'Replay',
}

const ProgressBarColors = {
  primary: '#E65D4F',
  secondary: '#4DA2EF',
  background: '#707070',
  loaded: '#838181',
};

const Player = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { category, type } = useParams();
  const { playerData } = useFetchMediaPlayerData(location.state);
  const [isPlaying, setIsPlaying] = useState(true);
  const [show, setShow] = useState(true);
  const [percentagePlayed, setPercentagePlayed] = useState(0);
  const [percentageLoaded, setPercentageLoaded] = useState(0);
  const [muted, setMuted] = useState(true);
  const [playerReady, setPlayerReady] = useState(false);
  const { routeTheme }: { routeTheme: RouteThemeProps } = useOutletContext();
  const { collect } = useAnalytics();

  const imgTitleAlt = t('@T_Back');

  const player = useRef<ReactPlayer>(null);
  const progressBar = useRef<HTMLInputElement>(null);
  const playerWrapper = useRef<HTMLDivElement>(null);

  const loadedInSeconds = player.current?.getSecondsLoaded();
  const isCompleted = +percentagePlayed === 1;
  const isTechnicallyCompleted = +percentagePlayed >= 0.9;

  useEffect(() => {
    return () => {
      playerReady && collect(AnalyticsEvents.Media.HAS_ABORTED_MEDIA, { area: `${type}-${category}`, contentId: playerData?.contentId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerReady]);

  useEffect(() => {
    isTechnicallyCompleted && collect(AnalyticsEvents.Media.HAS_COMPLETED_MEDIA, { area: `${type}-${category}`, contentId: playerData?.contentId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTechnicallyCompleted]);

  const handleStart = () => {
    collect(AnalyticsEvents.Media.HAS_STARTED_MEDIA, { area: `${type}-${category}`, contentId: playerData?.contentId });
  };

  useLayoutEffect(() => {
    const onLoad = (e: Event) => {
      setTimeout(() => {
        setIsPlaying(false);
        return () => {
          window.removeEventListener('load', onLoad);
        };
      }, 100);
    };
    window.addEventListener('load', onLoad);
  }, []);

  useEffect(() => {
    if (isCompleted) {
      setIsPlaying(false);
    }
  }, [isCompleted]);

  useEffect(() => {
    const Slider = playerWrapper.current;

    Slider?.style.setProperty(
      '--SliderColor',
      `${ProgressBarColors[routeTheme]}`,
    );
  }, [routeTheme]);

  useEffect(() => {
    setPercentageLoaded(
      ((player.current?.getSecondsLoaded() ?? 0) /
        (player.current?.getDuration() ?? 1)) *
        100,
    );
  }, [loadedInSeconds]);

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        setShow(false);
      }, 2500);
    }
  }, [show]);

  useEffect(() => {
    const handleHotKeys = (e: KeyboardEvent) => {
      switch (e.code) {
        case 'Space':
          setIsPlaying(!isPlaying);
          break;
        case 'ArrowRight':
          player.current?.seekTo(+player.current?.getCurrentTime() + 10);
          break;
        case 'ArrowLeft':
          player.current?.seekTo(player.current?.getCurrentTime() - 10);
          break;
      }
    };
    document.addEventListener('keyup', handleHotKeys);

    return () => {
      document.removeEventListener('keyup', handleHotKeys);
    };
  }, [percentagePlayed, isPlaying]);

  const showHeader = () => {
    return (
      <header className={'p-3 d-flex a-i-center w-100'}>
        <img
          src={backImgSrc}
          className={`c-pointer ${styles.back_button} mr-1`}
          height={37}
          width={37}
          alt={imgTitleAlt}
          onClick={() => {
            navigate(`/${type}/${category}`, {
              state: MediaCategory.TRAINING && {
                selectedId: playerData?.selectedId,
              },
            });
          }}
        />
        <div>
          <h5 className='font-17'>{t('@T_Back')}</h5>
          {category === MediaCategory.TRAINING && (
            <p className='font-17'>{t('@T_Training.Button_Text')}</p>
          )}
        </div>
      </header>
    );
  };

  const replay = () => {
    setPercentagePlayed(0);
    setIsPlaying(true);
    player.current?.seekTo(0);
  };

  const renderPlayerButtons = () => {
    if (isCompleted) {
      return (
        <img
          className={`c-pointer ${styles.center}`}
          onClick={replay}
          src={replayImgSrc}
          alt={PlayerStatus.Replay}
        />
      );
    } else {
      return (
        <img
          className={`c-pointer ${styles.center}`}
          onClick={e => {
            setIsPlaying(!isPlaying);
          }}
          src={isPlaying ? pauseImgSrc : playImgSrc}
          alt={isPlaying ? PlayerStatus.Pause : PlayerStatus.Play}
        />
      );
    }
  };

  const showTools = () => {
    !show && setShow(true);
  };

  const hideTools = () => {
    show && setShow(false);
  };

  const handleSeekChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const maxSeekValue = '0.9999';
    const seekValue =
      e.target.value > maxSeekValue ? maxSeekValue : e.target.value;
    setPercentagePlayed(parseFloat(seekValue));
    player.current?.seekTo(parseFloat(seekValue));
  };

  const handleProgress = (state: OnProgressProps) => {
    setPercentagePlayed(parseFloat(state.played.toString()));
  };

  return (
    <div className={`bg-black white ${styles.main}`}>
      <div
        ref={playerWrapper}
        className={`player-wrapper ${styles.center}`}
        onMouseMove={showTools}
        onMouseLeave={hideTools}
        onMouseDown={showTools}
      >
        <ReactPlayer
          url={playerData?.url ?? ''}
          muted={muted}
          playing={isPlaying}
          className={`${styles.player}`}
          ref={player}
          onProgress={handleProgress}
          onStart={handleStart}
          playsinline
          onReady={() => {
            setPlayerReady(true);
            setMuted(false);
          }}
        />
        {playerData?.type === MediaType.AUDIO && (
          <img
            src={playerData?.image ?? ''}
            alt={playerData?.title}
            className={`${styles.player}`}
          />
        )}
        <div className={show ? styles.show : 'o-0'}>
          {showHeader()}
          {renderPlayerButtons()}
          <div className={`px-4 w-100 ${styles.bottom_0}`}>
            <h5 className='font-24'>{playerData?.title}</h5>
            <div className={'d-flex py-5 mb-1'}>
              <p className='font-17'>
                {secondsToTime(player.current?.getCurrentTime() ?? 0)}
              </p>
              <div className='mx-3 w-100'>
                <input
                  ref={progressBar}
                  className={`w-100 bg-gray ${styles.progress_bar}`}
                  style={{
                    background: `linear-gradient(
                      to right,
                      ${ProgressBarColors[routeTheme]} 0%,
                      ${ProgressBarColors[routeTheme]} ${
                      percentagePlayed * 100
                    }%,
                      ${ProgressBarColors.loaded} ${percentagePlayed * 100}%,
                      ${ProgressBarColors.loaded} ${percentageLoaded}%,
                      ${ProgressBarColors.background} ${percentageLoaded}%,
                      ${ProgressBarColors.background} 100%
                    )`,
                  }}
                  type='range'
                  min={0}
                  max={1}
                  step='any'
                  value={percentagePlayed}
                  onChange={handleSeekChange}
                  onKeyDown={e => {
                    e.preventDefault();
                  }}
                />
              </div>
              <p className='font-17'>
                {secondsToTime(player.current?.getDuration() ?? 0)}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Player);
