export enum Routes {
  HOME = '/',
  LOG_IN = '/log-in',
  BODY_TRAINING = '/body/training',
  MIND_TRAINING = '/mind/training',
  BODY_EXERCISES = '/body/exercises',
  MIND_EXERCISES = '/mind/exercises',
  MIND_CALMING_SOUNDS = '/mind/calming-sounds',
  PLAYER = '/:type/:category/media/:id',
  IMPRINT = 'https://www.lifebonus.health/teamzugriff-impressum',
  TERMS_OF_USE = 'https://www.lifebonus.health/teamzugriff-nutzungsbedingungen',
}
