import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useOutletContext } from 'react-router-dom';
import Header from 'components/header/Header';
import SideBar from 'components/training/SideBar/SideBar';
import TrainingContent from 'components/training/TrainingContent/TrainingContent';
import { useMindTraining } from 'hooks/useMindTraining';

import mind from 'images/mindbg.png';
import { type IOutletContext } from 'globals/utils/types';

const MindTrainingLayout = () => {
  const { t } = useTranslation();
  const outletContext: IOutletContext = useOutletContext();
  const {
    packagesData,
    selectedPackage,
    setSelectedItem,
    tasksBySelectedPackage,
  } = useMindTraining(outletContext.data?.mind);

  const selectedPackageData =
    packagesData.find(item => item.id === selectedPackage) ?? null;

  return (
    <div className='row'>
      <SideBar
        type={t('@T_Movements')}
        data={packagesData}
        selectedId={selectedPackage}
        setSelectedId={setSelectedItem}
      />
      <TrainingContent
        bgImage={mind}
        bgImageBgcolor='bg-sea-blue'
        selectedId={selectedPackage}
        data={tasksBySelectedPackage}
        selectedItemData={selectedPackageData}
      >
        <Trans i18nKey='@T_Mind_Training_Description'>
          <strong>Here we go!</strong> Pick a topic. We recommend that you
          complete the topics one after the other.
        </Trans>
      </TrainingContent>
    </div>
  );
};

const MindTraining = () => {
  return (
    <React.Fragment>
      <Header />
      <MindTrainingLayout />
    </React.Fragment>
  );
};

export default MindTraining;
