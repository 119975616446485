import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useOutletContext } from 'react-router-dom';

import styles from './Home.module.scss';
import ExerciseCard from 'components/cards/ExerciseCard/ExerciseCard';
import TrainingCard from 'components/cards/TrainingCard/TrainingCard';
import { CardType, type IOutletContext } from 'globals/utils/types';
import logo from 'images/logo.svg';
import body from 'images/body.svg';
import mind from 'images/mind.svg';
import exercise from 'images/exercise.svg';
import logout from 'images/logout.svg';
import { Routes, LocalStorageKeys } from 'config';
import Modal from 'components/modal/Modal';

const Home = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const { data } = useOutletContext<IOutletContext>();
  const showTenantName = !!sessionStorage.getItem('QA');

  const imgTitleAlt = t('@T_Title');
  const imgLogoutAlt = t('@T_LogOut');

  const emptyBodyData = data?.body.blocks.length === 0;
  const emptyMindData = data?.mind.tasks.length === 0;

  const CardContent = {
    title: t('@T_Training.Title'),
    button_text: t('@T_Training.Button_Text'),
  };

  const BodyCardContent = {
    ...CardContent,
    description: t('@T_Body_Content'),
  };

  const MindCardContent = {
    ...CardContent,
    description: t('@T_Mind_Content'),
  };

  const handleLogout = () => {
    localStorage.removeItem(LocalStorageKeys.ACCESS_CODE);
    navigate(
      `${Routes.LOG_IN}/#code=${
        localStorage.getItem(LocalStorageKeys.TENANT_ACCESS_ID) ?? ''
      }`,
    );
  };

  return (
    <div className={`${styles.parent} col-12`}>
      <header className={`${styles.header} bg-lgray d-flex py-3 mb-3 col-12`}>
        <img
          src={logo}
          className={`ml-4 ${styles.app_logo}`}
          alt={imgTitleAlt}
        />
        <div className='px-3'>
          {showTenantName && (
            <p>
              <strong>Tenant: </strong>
              {data?.tenant?.name}
            </p>
          )}
          <h3 className='font-24'>{t('@T_Home.Title')}</h3>
          <p className='font-20'>{t('@T_Home.Sub_Title')}</p>
        </div>
      </header>
      <br />
      <main className='mt-4 px-2 mb-3'>
        {!emptyBodyData && (
          <div>
            <h4 className='o-80 px-2 font-20'>{t('@T_Body')}</h4>
            <div className='row'>
              <TrainingCard
                onClick={() => {
                  navigate(Routes.BODY_TRAINING, { state: {} });
                }}
                className='col-12 col-sm-6 col-md-8 col-lg-4 px-2 my-2'
                bgImage={styles.bg_body_card}
                bgImageBgcolor='bg-pink'
                cardData={BodyCardContent}
              />
              <div className='col-12 col-sm-6 col-md-4 col-lg-4 px-2 mt-2'>
                <ExerciseCard
                  onClick={() => {
                    navigate(Routes.BODY_EXERCISES);
                  }}
                  className={`col-12 ${styles.h_50} pb-2`}
                  icon={body}
                  title={t('@T_Exercises')}
                />
              </div>
            </div>
          </div> 
        )}
        {!emptyMindData && (
          <div>
            <h4 className='o-80 px-2 font-20 mt-5 pt-1'>{t('@T_Mind')}</h4>
            <div className='row'>
              <TrainingCard
                onClick={() => {
                  navigate(Routes.MIND_TRAINING, { state: {} });
                }}
                className='col-12 col-sm-6 col-md-8 my-2 col-lg-4 px-2'
                cardType={CardType.SECONDARY}
                bgImage={styles.bg_mind_card}
                bgImageBgcolor='bg-sea-blue'
                cardData={MindCardContent}
              />
              <div className='col-12 col-sm-6 col-md-4 mt-2 col-lg-4 px-2'>
                <ExerciseCard
                  onClick={() => {
                    navigate(Routes.MIND_EXERCISES);
                  }}
                  className={`col-12 ${styles.h_50} pb-2`}
                  cardType={CardType.SECONDARY}
                  icon={mind}
                  title={t('@T_Exercises')}
                />
                <ExerciseCard
                  onClick={() => {
                    navigate(Routes.MIND_CALMING_SOUNDS);
                  }}
                  className={`col-12 ${styles.h_50} pb-2`}
                  cardType={CardType.SECONDARY}
                  icon={exercise}
                  title={t('@T_Sounds')}
                />
              </div>
            </div>
          </div>
        )}
      </main>
      <footer
        className={`${styles.footer} bg-lgray px-4 py-2 row col-12 font-17`}
      >
        <a
          className='mr-5 my-1 link-primary'
          target='_blank'
          href={Routes.IMPRINT}
          rel='noreferrer'
        >
          {t('@T_Imprint')}
        </a>
        <a
          className='link-primary my-1'
          target='_blank'
          href={Routes.TERMS_OF_USE}
          rel='noreferrer'
        >
          {t('@T_TermsOfUse')}
        </a>
        <div
          onClick={() => {
            setShowModal(true);
          }}
          className='ml-auto my-1 d-flex c-pointer'
        >
          <img src={logout} height={20} width={20} alt={imgLogoutAlt} />
          <p className='link-primary ml-1'>{t('@T_LogOut')}</p>
        </div>
        <Modal
          show={showModal}
          onClose={() => {
            setShowModal(false);
          }}
          className={`${styles.br10} col-6`}
        >
          <div className={`${styles.modalIcon} bg-link-primary white d-flex`}>
            <h3 className='m-auto'>?</h3>
          </div>
          <div>
            <div className='mt-4 t-center col-8 mx-auto'>
              <Trans i18nKey='@T_Logout_Modal_Title'>
                <strong>Logout</strong>
                Oh no! You want to go? Are you sure you want to Log Out?
              </Trans>
            </div>
            <button
              onClick={handleLogout}
              className={`mx-auto d-block link-primary border-link-primary bg-white ${styles.border1} font-17 py-1 px-5 my-2`}
            >
              {t('@T_Yes_Log_Out')}
            </button>
            <button
              onClick={() => {
                setShowModal(false);
              }}
              className={'mx-auto d-block btn-primary font-17 py-1 px-5 mb-4'}
            >
              {t('@T_Remain_Logged_In')}
            </button>
          </div>
        </Modal>
      </footer>
    </div>
  );
};

export default Home;
