import axios from 'axios';

export const getTeamAccessData = async (
  tenantAccessId: string | null,
  accessCode: string | null,
  locale = 'en',
) => {
  const devUrl =
    'https://kdusrnea55cqnb63prczl5rtzq.appsync-api.eu-central-1.amazonaws.com/graphql';
  const prodUrl =
    'https://cjs6amtk6febdgza3cqaeupiwa.appsync-api.eu-central-1.amazonaws.com/graphql';
  const method = 'POST';
  const body =
    '{"query":"query GetTeamAccessData {\\n  getTeamAccessData\\n}\\n","variables":null,"operationName":"GetTeamAccessData"}';

  const isProd = window.location.hostname === 'team.lifebonus.health';

  const headers = {
    'x-api-key': isProd
      ? process.env.REACT_APP_GRAPHQL_API_KEY_PROD
      : process.env.REACT_APP_GRAPHQL_API_KEY_DEV,
    'x-tenant-access-id': tenantAccessId,
    'x-access-code': accessCode,
    'x-locale-key': locale,
  };

  try {
    const response = await axios({
      url: isProd ? prodUrl : devUrl,
      method,
      headers,
      data: body,
    });
    const parsed = JSON.parse(response.data.data.getTeamAccessData);
    /* eslint-disable @typescript-eslint/return-await */
    return parsed?.code === 200
      ? Promise.resolve(parsed.data)
      : Promise.reject(new Error(parsed.message, { cause: parsed.code }));
  } catch (e) {
    return Promise.reject(new Error('Unexpected error'));
  }
};
