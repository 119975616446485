import { gql, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { ContentfulMediaTranslationsQuery } from 'apis/ContentfulQueries';
import {
  type ContentfulMediaTranslationsQueryResult,
  Language,
  type ResolvedLanguage,
  type ContentfulMediaTranslationsQueryVariables,
  type ContentfulMediaTranslations,
} from 'globals/utils/types';

export const useContentfulMediaTranslations = () => {
  const { lng }: { lng: ResolvedLanguage } = useOutletContext();
  const [translations, setTranslations] = useState<
    Record<string, { key: string; value: string; description: string }>
  >({});

  const { data } = useQuery<
    ContentfulMediaTranslationsQueryResult,
    ContentfulMediaTranslationsQueryVariables
  >(
    gql`
      ${ContentfulMediaTranslationsQuery}
    `,
    {
      variables: { locale: Language[lng] },
    },
  );

  useEffect(() => {
    const datam: Record<string, ContentfulMediaTranslations> = {};
    data?.translationsCollection?.items.map(item => {
      return (datam[item.key] = item);
    });
    setTranslations(datam);
  }, [data, lng]);

  return {
    translations,
  };
};
