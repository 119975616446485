import React, { type ReactNode } from 'react';

import styles from './Modal.module.scss';

interface ModalProps {
  children: ReactNode;
  className?: string;
  show: boolean;
  onClose?: () => void;
}

const Modal = ({ children, className, show, onClose }: ModalProps) => {
  return (
    <div className={`${!show ? 'd-none' : styles.modal}`} onClick={onClose}>
      <div
        className={`bg-white ${className} ${styles.modalMain} `}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default Modal;
