import { useCallback } from 'react';
import { useOutletContext } from 'react-router-dom';
import { usePostHog } from 'posthog-js/react';
import { type IOutletContext } from 'globals/utils';

const POSTHOG_APP_NAME = 'Team Access';

export const useAnalytics = () => {
  const posthog = usePostHog();
  const outletContext: IOutletContext = useOutletContext();

  const collect = useCallback(
    async <T extends AnalyticEventName>(
      eventName: T,
      props: AnalyticEventProps<T>,
    ) => {
      const baseProps = {
        applicationName: POSTHOG_APP_NAME,
        tenantId: outletContext?.data?.tenant.id,
      };
      posthog?.capture(eventName, { ...baseProps, ...props });
    },
    [posthog, outletContext?.data?.tenant.id],
  );

  return { collect };
};

export const AnalyticsEvents = {
  Media: {
    HAS_STARTED_MEDIA: 'has_started_media',
    HAS_ABORTED_MEDIA: 'has_aborted_media',
    HAS_COMPLETED_MEDIA: 'has_completed_media',
  },
  General: {
    TEST_EVENT: 'test_event',
  },
} as const;

interface EventToPropsMapping {
  [AnalyticsEvents.Media.HAS_STARTED_MEDIA]: {
    area: string;
    contentId?: string;
  };
  [AnalyticsEvents.Media.HAS_ABORTED_MEDIA]: {
    area: string;
    contentId?: string;
  };
  [AnalyticsEvents.Media.HAS_COMPLETED_MEDIA]: {
    area: string;
    contentId?: string;
  };
  [AnalyticsEvents.General.TEST_EVENT]: null;
}

type AnalyticEventName = keyof EventToPropsMapping;
type AnalyticEventProps<T extends AnalyticEventName> = EventToPropsMapping[T];
