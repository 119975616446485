import React, { type ReactNode } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import styles from './TrainingContent.module.scss';
import {
  type SelectedTrainingContentData,
  type TrainingTask,
  type MindTask,
  CardType,
  MediaType,
} from 'globals/utils/types';
import Time from 'components/time/Time';
import video_primary from 'images/video-primary.svg';
import video_secondary from 'images/video-secondary.svg';

interface TrainingContentProps {
  bgImage: string;
  bgImageBgcolor: string;
  selectedId: string;
  children: ReactNode;
  data: TrainingTask[] | MindTask[];
  selectedItemData: SelectedTrainingContentData | null;
}

const MediaIcons = {
  [CardType.PRIMARY]: video_primary,
  [CardType.SECONDARY]: video_secondary,
};

const TrainingContent = ({
  bgImageBgcolor,
  bgImage,
  selectedId,
  children,
  data,
  selectedItemData,
}: TrainingContentProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { routeTheme }: { routeTheme: CardType } = useOutletContext();
  const imgTitleAlt = t('@T_Play');

  return (
    <div className={`bg-gray font-20 ${styles.w_max_content}`}>
      {selectedId ? (
        <React.Fragment>
          <div>
            <div
              className={`bg-gray w-100 h-100 o-80 ${styles.bg_main_image_overlay} ${styles.z_index_1}`}
            />
            <img
              src={selectedItemData?.imgSrc ?? ''}
              className={`w-100 h-100 ${styles.bg_main_image}`}
              alt={selectedItemData?.title}
            />
            <div className={`${styles.z_index_1} p-3 pb-2`}>
              <h3 className={'font-24'}>{selectedItemData?.title}</h3>
              <h6 className={'font-20 font-w-n'}>
                {selectedItemData?.description}
              </h6>
              <h5 className={'font-20 pt-4'}>{t('@T_Episode_Overview')}</h5>
            </div>
          </div>
          <div>
            {data.map(item => {
              const playerData = {
                url: item.content.audioSrc ?? item.content.videoSrc,
                title: item.content.title,
                image: item.content.imgSrc,
                type: item.content.audioSrc ? MediaType.AUDIO : MediaType.VIDEO,
                selectedId,
              };
              return (
                <div
                  key={item.id}
                  className={'px-3 py-1 pointer'}
                  onClick={() => {
                    navigate(`media/${item.id}`, { state: playerData });
                  }}
                >
                  <div className='d-flex'>
                    <div className={styles.card_img_parent}>
                      <img
                        className={`h-100 w-100 ${styles.card_img}`}
                        src={item.content.imgSrc ?? ''}
                        alt={item.content.title}
                      />
                      <img
                        src={MediaIcons[routeTheme]}
                        alt={imgTitleAlt}
                        className={`${styles.playicon} m-auto`}
                      />
                    </div>
                    <div className={'p-1'}>
                      <h5 className={`font-17 link-${routeTheme}`}>
                        {item.content.title}
                      </h5>
                      <p className={'font-17'}>{item.content.body}</p>
                      <div className={styles.time}>
                        <Time duration={item.content.durationSeconds} />
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.border_bottom} bg-white mt-1`} />
                </div>
              );
            })}
          </div>
        </React.Fragment>
      ) : (
        <div
          className={`h-100 t-center a-c-center d-grid ${styles.w_273} m-auto`}
        >
          <div
            className={`${styles.bg_image_background} ${bgImageBgcolor} pt-2 mb-3`}
          >
            <img
              src={bgImage}
              className={`w-100 h-100 ${styles.bg_image}`}
              alt='training-overview'
            />
          </div>
          {children}
        </div>
      )}
    </div>
  );
};

export default TrainingContent;
