import { useMemo, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { type ITeamAccessMindData } from '../globals/utils/types';

export const useMindTraining = (data?: ITeamAccessMindData) => {
  const location = useLocation();

  const [selectedPackage, setSelectedPackage] = useState('');

  useEffect(() => {
    if (location.state) {
      setSelectedPackage(location?.state?.selectedId);
    } else {
      setSelectedPackage('');
    }
  }, [location.state, location.state?.selectedId]);

  const packages = useMemo(() => data?.packages ?? [], [data]);
  const tasks = useMemo(() => data?.tasks ?? [], [data]);

  const tasksBySelectedPackage = tasks.filter(
    item => item.packageId === selectedPackage,
  );

  const packagesData = packages.map(item => ({
    id: item.id,
    title: item.content.title,
    description: item.content.body,
    imgSrc: tasksBySelectedPackage[0]?.content?.imgSrc,
  }));

  const setSelectedItem = (id: string) => {
    setSelectedPackage(id);
  };

  return useMemo(
    () => ({
      packagesData,
      selectedPackage,
      setSelectedItem,
      tasks,
      tasksBySelectedPackage,
    }),
    [packagesData, selectedPackage, tasks, tasksBySelectedPackage],
  );
};
