import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useOutletContext } from 'react-router-dom';

import styles from './MediaCards.module.scss';
import { useViewport } from 'contexts/viewport/Viewport';
import { CardType, MediaType, type MediaCardResult } from 'globals/utils/types';
import { secondsToTime } from 'globals/utils/utils';
import { useContentfulMediaTranslations } from 'hooks/useContentfulMediaTranslations';
import audio from 'images/audio.svg';
import video_primary from 'images/video-primary.svg';
import video_secondary from 'images/video-secondary.svg';

interface MediaCardsProps {
  data: Record<string, MediaCardResult[]>;
}

const MediaIcons = {
  [`${CardType.PRIMARY}-${MediaType.VIDEO}`]: video_primary,
  [`${CardType.SECONDARY}-${MediaType.VIDEO}`]: video_secondary,
  [`${CardType.PRIMARY}-${MediaType.AUDIO}`]: audio,
  [`${CardType.SECONDARY}-${MediaType.AUDIO}`]: audio,
};

const MediaCards = ({ data }: MediaCardsProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { routeTheme }: { routeTheme: string } = useOutletContext();
  const { translations } = useContentfulMediaTranslations();
  const { width } = useViewport();
  const today = new Date();
  const cardWidth = 350;
  const cardPadding = 16;
  const cardSize = cardWidth + cardPadding;
  const containerPadding = 48;
  const reminder = ((width - containerPadding) / cardSize) % 1;

  return (
    <div className={styles.overflow_y_scroll}>
      {Object.keys(data).map(heading => (
        <div
          key={heading}
          className={
            translations[`@T_${heading}`]?.value ? 'p-3 pb-0' : 'd-none'
          }
        >
          <h3 className='font-20'>{translations[`@T_${heading}`]?.value}</h3>
          <div className={`d-flex ${styles.cards_parent}`}>
            {data[heading].map(item => {
              const newLabelExpiryDate = new Date(
                item.expiryDateForNew || today,
              );
              const isNew = newLabelExpiryDate.getTime() > today.getTime();
              const playerData = {
                url: item.media.url,
                title: item.title,
                image: item.image.url,
                type: item.contentType,
              };
              return (
                <div
                  className={'p-2 pl-0 col pointer'}
                  key={item.sys.id}
                  onClick={() => {
                    navigate(`media/${item.sys.id}`, { state: playerData });
                  }}
                >
                  <div
                    className={`bg-gray pb-2 ${styles.card} h-100`}
                    style={{
                      width: `${
                        reminder < 0.1
                          ? cardWidth - 26
                          : reminder > 0.9
                          ? cardWidth - 50
                          : cardWidth
                      }px`,
                    }}
                  >
                    <div className='d-flex'>
                      <img
                        src={item.image.url}
                        alt={item.title}
                        className={`${styles.image} w-100`}
                      />
                      <img
                        src={MediaIcons[`${routeTheme}-${item.contentType}`]}
                        alt={item.title}
                        className={styles.badge}
                      />
                      {isNew && (
                        <div className={styles.new_badge}>
                          <button className='font-14 white bg-black'>
                            {t('@T_New')}
                          </button>
                        </div>
                      )}
                      <div className={styles.time}>
                        <button className='font-14'>
                          {secondsToTime(item.durationSeconds)}
                        </button>
                      </div>
                    </div>
                    <h5 className={`font-17 p-1 link-${routeTheme}`}>
                      {item.title}
                    </h5>
                    <p className='font-14 px-1'>{item.infoContent}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ))}
    </div>
  );
};

export default MediaCards;
